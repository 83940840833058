import HttpClient from '@/services/index/httpBase'

export default class Company extends HttpClient {
  constructor() {
    super()
  }

  async list(page = 1) {
    return await this.request({
      url: '/admin/companies?page=' + page,
      method: 'GET',
    })
  }

  async get(id) {
    return await this.request({
      url: `/company/${id}`,
      method: 'GET',
    })
  }

  async status(id) {
    return await this.request({
      url: `/company/${id}/status`,
      method: 'GET',
      data: null,
    })
  }

  async update(id, payload) {
    return await this.request({
      url: `/company/${id}`,
      method: 'PUT',
      data: payload,
    })
  }

  async lastImport(id) {
    return await this.request({
      url: `/company/${id}/lastImport`,
      method: 'GET',
      data: null,
    })
  }
}
