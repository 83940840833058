import HttpClient from '@/services/index/httpBase'

export default class ThemesService extends HttpClient {
  constructor() {
    super()
  }

  async getDefaultTheme(company) {
    return await this.request({
      url: `company/${company}/themes`,
      method: 'GET',
    })
  }

  async getTheme(company, theme) {
    return await this.request({
      url: `company/${company}/themes/${theme}`,
      method: 'GET',
    })
  }

  async addTheme(theme) {
    return await this.request({
      url: `company/${theme}`,
      method: 'POST',
    })
  }

  async modifyTheme(company, theme) {
    return await this.request({
      url: `company/${company}/themes/${theme}`,
      method: 'PATCH',
    })
  }

  async deleteTheme(company, theme) {
    return await this.request({
      url: `company/${company}/themes/${theme}`,
      method: 'DELETE',
    })
  }

  async getTeamTheme(team) {
    return await this.request({
      url: `team/${team}/theme`,
      method: 'GET',
    })
  }
}
