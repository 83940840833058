<template>
  <v-snackbar
    :color="notification.color"
    elevation="0"
    v-model="visible"
    top
    center
    timeout="-1"
  >
    <div class="d-flex align-center pl-2">
      <v-icon class="mr-2">{{ notification.icon }}</v-icon>
      <v-badge
        color="red"
        :content="errors"
        overlap
        class="align-content-center pr-1 pb-1 pl-1"
      ></v-badge>
      <span class="mr-2 ml-5">{{ lang.error.default[notification.key] }}</span>
      <v-spacer></v-spacer>
      <v-btn icon @click="closeAllNotifications">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <div class="d-flex justify-center align-center">
      <v-btn
        small
        outlined
        @click="closeNotification"
        class="d-flex align-center pr-1"
      >
        <span>{{ lang.button.next }}</span>
        <v-icon class="ml-1">mdi-chevron-right</v-icon>
      </v-btn>
    </div>
  </v-snackbar>
</template>

<script setup>
import { computed, getCurrentInstance, ref } from 'vue'

const instance = getCurrentInstance().proxy
const store = computed(() => instance.$store)
const lang = computed(() => instance.$lang)

const props = defineProps({
  notification: Object,
  index: Number,
})

const visible = ref(true)
const errors = computed(() => {
  return `${props.index + 1}`
})

const closeNotification = () => {
  visible.value = false
  store.value.commit('removeMessage', props.index)
}

const closeAllNotifications = () => {
  visible.value = false
  store.value.commit('removeAllMessages')
}
</script>
<style scoped>
>>> .v-snack__content {
  padding: 5px 0 8px 4px;
}
</style>
