import HttpClient from '@/services/index/httpBase'

export default class RecommendationsService extends HttpClient {
  constructor() {
    super()
  }

  async check(id, payload) {
    return await this.request({
      url: `/recommendations/${id}/check`,
      method: 'PATCH',
      data: payload,
    })
  }

  async listCategories() {
    return await this.request({
      url: '/recommendation-categories',
      method: 'GET',
    })
  }
  async list() {
    return await this.request({
      url: '/recommendations',
      method: 'GET',
    })
  }

  async get(id) {
    return await this.request({
      url: `/recommendations/${id}`,
      method: 'GET',
    })
  }

  async create(payload) {
    return await this.request({
      url: `/recommendations`,
      method: 'POST',
      data: payload,
    })
  }

  async delete(id) {
    return await this.request({
      url: `/recommendations/${id}`,
      method: 'DELETE',
    })
  }

  async update(id, payload) {
    return await this.request({
      url: `/recommendations/${id}`,
      method: 'PUT',
      data: payload,
    })
  }

  async setStatus(id, payload) {
    return await this.request({
      url: `/recommendations/${id}/status`,
      method: 'PATCH',
      data: payload,
    })
  }

  async setPriority(id, payload) {
    return await this.request({
      url: `/recommendations/${id}/priority`,
      method: 'PATCH',
      data: payload,
    })
  }

  async setImpact(id, payload) {
    return await this.request({
      url: `/recommendations/${id}/impact`,
      method: 'PATCH',
      data: payload,
    })
  }

  async setDueDate(id, payload) {
    return await this.request({
      url: `/recommendations/${id}/due-date`,
      method: 'PATCH',
      data: payload,
    })
  }

  async setAssignee(id, payload) {
    return await this.request({
      url: `/recommendations/${id}/assign`,
      method: 'PATCH',
      data: payload,
    })
  }
}
