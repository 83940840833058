export const errorsDefault = {
  404: {
    color: 'red',
    icon: 'mdi-alert-outline',
  },
  500: {
    color: 'red',
    icon: 'mdi-server',
  },
  default: {
    color: 'red',
    icon: 'mdi-alert-circle',
  },
}
