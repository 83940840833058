import { errorsLogin } from '@/services/errors/errorsLogin'
import { errorsDefault } from '@/services/errors/errorsDefault'

export const errorsStrategy = {
  login: {
    errors: errorsLogin,
  },
  default: {
    errors: errorsDefault,
  },
}
