export const errorsLogin = {
  401: {
    color: 'red',
    icon: 'mdi-login',
  },
  403: {
    color: 'red',
    icon: 'mdi-shield-off',
  },
  404: {
    color: 'red',
    icon: 'mdi-shield-off',
  },
  500: {
    color: 'red',
    icon: 'mdi-alert-circle',
  },
  default: {
    color: 'red',
    icon: 'mdi-alert',
  },
}
