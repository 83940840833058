import HttpClient from '@/services/index/httpBase'

export default class IssueChannels extends HttpClient {
  constructor() {
    super()
  }

  async list() {
    return await this.request({
      url: `/settings/issues/issue_channels`,
      method: 'GET',
    })
  }

  async create(data) {
    return await this.request({
      url: `/settings/issues/issue_channels`,
      method: 'POST',
      data,
    })
  }

  async find(id) {
    return await this.request({
      url: `/settings/issues/issue_channels/${id}`,
      method: 'GET',
    })
  }

  async update(id, data) {
    return await this.request({
      url: `/settings/issues/issue_channels/${id}`,
      method: 'PUT',
      data,
    })
  }

  async delete(id) {
    return await this.request({
      url: `/settings/issues/issue_channels/${id}`,
      method: 'DELETE',
    })
  }
  async getUsers(id, data) {
    return await this.request({
      url: `/settings/issues/issue_channels/${id}/users`,
      method: 'POST',
      data,
    })
  }
  async setIssueStatus(id, data) {
    return await this.request({
      url: `/settings/issues/issue/${id}/status`,
      method: 'PUT',
      data,
    })
  }
  async getPriorities(id) {
    return await this.request({
      url: `/settings/issues/issue_channels/${id}/priorities`,
      method: 'POST',
    })
  }
}
