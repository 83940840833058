import HttpClient from '@/services/index/httpBase'

export default class RecommendationGroupService extends HttpClient {
  constructor() {
    super()
  }

  async list() {
    return await this.request({
      url: '/recommendation-groups',
      method: 'GET',
    })
  }

  async get(id) {
    return await this.request({
      url: `/recommendation-groups/${id}`,
      method: 'GET',
    })
  }

  async create(payload) {
    return await this.request({
      url: `/recommendation-groups`,
      method: 'POST',
      data: payload,
    })
  }

  async delete(id) {
    return await this.request({
      url: `/recommendation-groups/${id}`,
      method: 'DELETE',
    })
  }

  async update(id, payload) {
    return await this.request({
      url: `/recommendation-groups/${id}`,
      method: 'PUT',
      data: payload,
    })
  }

  async attach(id, payload) {
    return await this.request({
      url: `/recommendation-groups/${id}/attach`,
      method: 'POST',
      data: payload,
    })
  }

  async detach(id, idToDetach) {
    return await this.request({
      url: `/recommendation-groups/${id}/detach/${idToDetach}`,
      method: 'DELETE',
    })
  }
}
