import store from '../../store'
import { errorsStrategy } from '../errors/errorsStrategy'

export class HttpErrorHandler {
  #status

  constructor(response, status) {
    this.response = response
    this.#status = status
  }

  get messageError() {
    const isAuth = store.getters.isAuth
    const strategy = isAuth
      ? errorsStrategy.default.errors
      : errorsStrategy.login.errors

    const error = this.getErrorByStatus(strategy, this.#status)
    return error ? error : null
  }

  getErrorByStatus(strategy, status) {
    if (strategy && strategy[status]) {
      return strategy[status]
    }
    return strategy ? strategy.default : null
  }

  async pushMessage() {
    const error = this.messageError
    if (error) {
      const { color, icon } = error
      const key = this.#status || 'default'
      await store.dispatch('pushMessage', {
        key,
        color,
        icon,
      })
    }
  }

  async execute() {
    if (this.#status >= 200 && this.#status < 300) {
      return this.response
    }
    await this.pushMessage()
    return this.response
  }
}
