import HttpClient from '@/services/index/httpBase'

export default class PipelineService extends HttpClient {
  constructor() {
    super()
  }

  async aggregate(url, params = {}, method = 'GET', data = {}) {
    return await this.request({
      url: '/v2/pipeline/' + url,
      method: method,
      params,
      data,
    })
  }
}
