import HttpClient from '@/services/index/httpBase'

export default class ActivitiesService extends HttpClient {
  constructor() {
    super()
  }

  async list(params) {
    return await this.request({
      url: '/activities',
      method: 'GET',
      params,
    })
  }
}
