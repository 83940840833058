module.exports = {
  access: 'Accès insuffisant',
  auth: 'Vérifiez vos identifants',
  basic: 'Vérifiez ce champ',
  company: 'Créez une compagnie',
  demo: 'Action limitée via Demo',
  empty: 'Cet élément est obligatoire',
  forbidden: 'Accès non autorisé',
  forbiddenMessage: 'Echoué ou expiré',
  incomplete: 'Incomplet',
  input: 'Vérifiez vos informations',
  invalidConfirmCode: "Le code saisi n'est pas valide",
  invalidEmail: 'Veuillez entrer une adresse mail valide',
  limit: 'Accès limité (Redirection)',
  loginFirst: 'Connectez-vous',
  noData: 'Données non trouvées ou acquisition en cours',
  notificationMessage: 'Un défaut est survenu',
  notificationTitle: 'Erreur',
  offer: 'Vérifiez votre offre',
  settingFirst: 'Ajouter un connecteur valide',
  subscribeFirst: 'Augmenter votre offre',
  success: 'Action réussie',
  warn: 'Attention',
  share: {
    create: 'Impossible de créer un partage',
    update: 'Impossible de mettre à jour votre partage',
    delete: 'Impossible de supprimer ce partage',
  },
  title400: 'Attention',
  text400: 'Erreur de requête',
  title401: 'Non connecté',
  text401: 'Vous devez être connecté pour continuer',
  title403: 'Accès refusé',
  text403: "Vous n'avez pas accès à cette fonctionnalité",
  title404: 'Non trouvé',
  text404: "Cette ressource n'existe pas",
  login: {
    401: "Échec de l'authentification. Veuillez réessayer.",
    403: "Vous n'avez pas la permission d'accéder à cette ressource.",
    404: 'Vos identifiants sont incorrects ou la méthode de connexion est invalide.',
    500: 'Erreur interne du serveur lors de la connexion.',
    default:
      'Une erreur inconnue est survenue lors de la connexion. Veuillez réessayer.',
  },
  default: {
    404: 'Ressource non trouvée.',
    500: 'Erreur interne du serveur. Veuillez réessayer plus tard.',
    default: 'Une erreur inattendue est survenue.',
  },
}
