import HttpClient from '@/services/index/httpBase'

export default class DataView extends HttpClient {
  constructor() {
    super()
  }

  async create(data) {
    return await this.request({
      url: '/data-views/',
      method: 'POST',
      data,
    })
  }

  async list() {
    return await this.request({
      url: '/data-views/',
      method: 'GET',
    })
  }

  async get(id) {
    return await this.request({
      url: `/data-views/${id}`,
      method: 'GET',
    })
  }

  async update(id) {
    return await this.request({
      url: `/data-views/${id}`,
      method: 'PUT',
    })
  }

  async delete(id) {
    return await this.request({
      url: `/data-views/${id}`,
      method: 'DELETE',
    })
  }
  async multiScopeCreate(data) {
    return await this.request({
      url: '/multi-scope-data-views/',
      method: 'POST',
      data,
    })
  }
}
